
.App{
  width: 96%;
  max-width:1200px;
  margin: 2% auto;
  padding-top: 120px;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.toolbar {
  position:fixed;
  top: 10px;
  /* width: 100%; */
  width: 93%;
  max-width: 1180px;
  /* margin: 2% auto; */
  z-index: 1;
}
.editor-wrapper {

  padding:10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.whiteback {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100%;
  z-index: 1;
  background: #fff;
}