blockquote{
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
tbody, tr, td {
    border: inherit;
}
table{
    border-collapse: collapse;
    table-layout: fixed;
}
button{
    background-color: white;
    border:none;
    opacity: 0.5;
}
.btnActive{
    opacity: 1;
}
.editor-wrapper{
    padding-top: 30px !important;
    font-family:'Helvetica','Arial', sans-serif;
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;
    min-height: 400px;
    height: fit-content;
}
table{
    width:100%;
}
td{
    height: 50px;
    padding:0 5px;
}
.popup-wrapper{
    display: inline;
    position: relative;
}
.ipopup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;
    cursor: default;
    width:800px;
}
.popup-gallery {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height:600px;
    overflow-y: scroll;
}
.popup-image {
    width:9.5%;
    align-self: center;
    cursor: pointer;
    padding:10px;
}

.popup-image:hover {
    padding:9px;
    border: solid 1px #000;
}
.popup-image.selected {
    padding:7px;
    border: solid 3px #000;
}
.popup-image img{
    width:100%;
    height: auto;
}
.popup-button {
    white-space: nowrap;
    padding:10px;
    color: #000000 !important;
    background: #d9d9d9;
    border-radius: 5px;
    width: unset !important;
    opacity: 1;
    display:inline-block;
}
.popup form {
    display: inline-block;
    width: 80%;
}
.popup input[name="image"] {
    display: none;
}
button{
    cursor: pointer;
}
p {
    margin:0;
}

.popupinput {
    margin-left: 10px;
}
.popupsavebutton {
    margin-left:20px !important;
    white-space: nowrap;
    padding:5px 30px !important;
    color: #fff !important;
    background: #000000;
    border-radius: 5px;
    width: unset !important;
    opacity: 1;
    display:inline-block;
    font-weight:bold;
    border:solid 2px #000;
}

.App.modal {
    width:80%;
    margin-left:0px;
}

.App.modal .toolbar{
    width:78%;
    margin-top:0;
}
.App.modal .inputList{
    width:22%;
    display: flex !important;
    flex-direction: column;
    height:90vh;
}

.App.modal .inputList > div{
    flex:0;
}
.App.modal .inputList > div:nth-child(2){
    flex:1 !important;
    overflow-y: scroll;
}