.toolbar{
    border-radius: 10px;
background: #ffffff;
box-shadow:  -8px 8px 13px #ededed,
             8px -8px 13px #ffffff;
    margin:20px 0;
    display:flex;
    flex-wrap:wrap;
    align-items:center;
    padding: 15px 10px;
    row-gap: 5px;
}
.toolbar-grp>*{
    cursor: pointer;

}
.toolbar-grp{
    padding:5px;
    margin-left: 10px;
    padding-right: 10px;
    display:flex;
    border-right: solid 1px #d2d2d2;
}
.toolbar-grp.temp {
    box-shadow: #afafaf 0px 0px 5px;
}
.toolbar-grp.no-border {
    border: none;
    padding:0;
    margin:0;
}
select{
    height: 30px;
    border: none;
    width: 6.9rem;
}
.toolbar-grp button{
    height:30px !important;
    width:30px;
    padding:5px;
}