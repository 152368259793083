.inputList {
  position: absolute;
  left: 101%;
  top: 0;
  background: rgb(255, 255, 255);
  padding:5px;
  z-index:90;
  box-shadow: black 0 0 5px;
  cursor: default;
}
.inputList Button {
  white-space: nowrap;
  background: #000000;
  color: #fff;
  width: 90px !important;
  margin: 5px auto !important;
  display: block;
}
.icon {
  cursor: pointer;
  color: #494949;
  position: absolute;
  top:0px;
  right:0px;
  background:white;
}
.inputOption {
  border:2px solid #e8e8e8;
  padding:3px;
  margin-top:3px;
  font-weight: 600;
  cursor:pointer;
}
.inputOption.selected {
  border:2px solid #575757;
}